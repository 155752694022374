/* In your global styles.scss file */
@import "../../node_modules/bootstrap/scss/bootstrap";

::ng-deep .alert.alert-primary {
    background-color: map-get($theme-colors, "primary") !important;
    border-color: map-get($theme-colors, "primary") !important;
    color: #fff !important; /* Or any other text color you prefer */
  }
  
  ::ng-deep .alert.alert-secondary {
    background-color: map-get($theme-colors, "secondary") !important;
    border-color: map-get($theme-colors, "secondary") !important;
    color: #fff !important; /* Or any other text color you prefer */
  }
  
  ::ng-deep .alert.alert-success {
    background-color: map-get($theme-colors, "success") !important;
    border-color: map-get($theme-colors, "success") !important;
    color: #fff !important; /* Or any other text color you prefer */
  }
  
  ::ng-deep .alert.alert-info {
    background-color: map-get($theme-colors, "info") !important;
    border-color: map-get($theme-colors, "info") !important;
    color: #000 !important; /* Or any other text color you prefer */
  }
  
  ::ng-deep .alert.alert-warning {
    background-color: map-get($theme-colors, "warning") !important;
    border-color: map-get($theme-colors, "warning") !important;
    color: #000 !important; /* Or any other text color you prefer */
  }
  
  ::ng-deep .alert.alert-danger {
    background-color: map-get($theme-colors, "danger") !important;
    border-color: map-get($theme-colors, "danger") !important;
    color: #fff !important; /* Or any other text color you prefer */
  }
  
  ::ng-deep .alert.alert-light {
    background-color: map-get($theme-colors, "light") !important;
    border-color: map-get($theme-colors, "light") !important;
    color: #000 !important; /* Or any other text color you prefer */
  }
  
  ::ng-deep .alert.alert-dark {
    background-color: map-get($theme-colors, "dark") !important;
    border-color: map-get($theme-colors, "dark") !important;
    color: #fff !important; /* Or any other text color you prefer */
  }