/* You can add global styles to this file, and also import other style files */
@import '../node_modules/@swimlane/ngx-datatable/index.scss';
@import '../node_modules/@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import '../node_modules/ngx-ui-switch/ui-switch.component.scss';
// @import "~@ng-select/ng-select/themes/default.theme.css";

$theme-colors: (
  "primary": #e1087d,
  "secondary": #E83D4C,

  "dark": #0D1220 // replace with your preferred shade of dark grey
);

a {
  color: var(--bs-primary);
}

a:visited {
  color: var(--bs-primary); /* Or another relevant color from your theme */
}

// not working
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus
.nav-link:hover, .nav-link:focus {
  color: white!important;
}

/* Tab bar and tab styles */
.nav-tabs {
  background-color: #0D1220;
  .nav-item {
    .nav-link{
      color: #fff;
    }
    .nav-link.active,  .nav-link:hover{
      background-color: #e1087d;
      color: #fff;
    }
  }
}


/* Make ngx-datatable fill the width of the screen */
/* Ensure the container of ngx-datatable allows for full width */
/* You may need to adjust the selector based on your HTML structure */
.ngx-datatable {
  width: 100% !important; /* Ensure the table is full width */
}

.ngx-datatable .datatable-footer .page-count,
.ngx-datatable .datatable-footer .datatable-pager {
  // Apply Bootstrap button styles
  .page-link {
    color: #007bff; // Bootstrap primary color
    background-color: #fff;
    border: 1px solid #dee2e6;
    &:hover {
      text-decoration: none;
      background-color: #e9ecef;
      border-color: #dee2e6;
    }
  }

  .pager a{
    color: #007bff; // Bootstrap primary color
    background-color: #fff;
    border: 1px solid #dee2e6;
    &:hover {
      text-decoration: none;
      background-color: #e9ecef;
      border-color: #dee2e6;
    }
  }

  .page-item.active .page-link {
    color: #fff;
    background-color: #007bff; // Bootstrap primary color
    border-color: #007bff;
  }

  // Add any other Bootstrap button styles you need
}
/* Add the following styles to make ngx-datatable fill the width of the screen */

.ngx-datatable {
  width: 100% !important; /* Ensure the table is full width */
}

/* Ensure the container of ngx-datatable allows for full width */
/* You may need to adjust the selector based on your HTML structure */


// radio and switch sizes
.form-check-label{
  vertical-align: -webkit-baseline-middle;
}

.form-check-input {
  font-size: x-large;
}

.form-check-input:checked {
  background-color: #e1087d!important;
  border-color: #e1087d!important;
}

.ngx-slider .ngx-slider-pointer {
  background-color: #e1087d!important;
}

.ngx-slider .ngx-slider-pointer:after {
  background-color: white!important;
}

//datepicker colour change
.bs-datepicker-head {
  background-color: #e1087d!important;
}

.switch.checked {
  background-color: #e1087d!important;
  border-color: #e1087d!important;
}


.cursor-pointer, .cursor-pointer input {
  cursor: pointer;
}


.nav-link.active{
  color: yellow!important;
}
.nav-link, .nav-link:visited {
  color: white !important;
}

.nav-link:hover {
  color: #e1087d!important;
}


/* Importing Bootstrap SCSS file. */

// Use the primary color from $theme-colors as the default button color
$btn: map-get($theme-colors, "primary");
.btn.btn-primary{
  color: white;
}


@import "./node_modules/bootstrap/scss/bootstrap";

/* Ensure toastr container is fixed at the bottom right of the viewport */
#toast-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

//Generate utilites for Cursor
$utilities: map-merge(
  $utilities,
  (
    "cursor": (
      property: cursor,
      values: auto default pointer wait text move help not-allowed
    ),
  )
);


/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
